<template>
  <page>
    <div class="deliveryTimeManage">
      <el-form :model="params" label-width="120px" ref="filtersForm">
        <el-form-item label="配送时间提醒:" prop="sendConfig">
          <el-switch
            style="margin-left: 10px"
            v-model="params.sendConfig"
            :active-value="true"
            :inactive-value="false"
          >
          </el-switch>
        </el-form-item>
        <el-form-item
          v-if="params.sendConfig"
          label="提醒时间:"
          prop="remindTime"
        >
          <el-time-picker
            v-model="params.remindTime"
            placeholder="请设置提醒时间"
          >
          </el-time-picker>

          <p class="tips">
            在设置的提醒时间前，门店完成下单，提示将在明天进行配送；在提示的时间后完成下单，提示门店订单将在后天进行配送
          </p>
        </el-form-item>

        <el-form-item label="">
          <el-button type="primary" :loading="btnLoading" @click="submit"
            >保存</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </page>
</template>

<script>
export default {
  data() {
    return {
      btnLoading: false,
      params: {
        sendConfig: true,
        remindTime: ''
      }
    }
  },
  created() {
    this.getConfigInfo()
  },

  methods: {
    async getConfigInfo() {
      try {
        const res = await this.$api.deliveryTimeManage.getConfigInfo()
        this.params.sendConfig = res.deliveryTime.isRemind == 1 ? true : false

        if (res.deliveryTime.remindTime) {
          const time = res.deliveryTime.remindTime.split(':')
          this.params.remindTime = new Date(
            2021,
            12,
            3,
            +time[0],
            +time[1],
            +time[2]
          )
        }
      } catch (e) {
        console.log(e)
      }
    },
    async submit() {
      if (!this.params.remindTime && this.params.sendConfig) {
        this.$message.warning('提醒时间不能为空')
        return
      }
      this.btnLoading = true

      try {
        await this.$api.deliveryTimeManage.operate({
          isRemind: this.params.sendConfig ? 1 : 0,
          remindTime: this.$utils.dateFormat(this.params.remindTime, 'HH:mm:ss')
        })
        this.$message.success('保存成功！')
      } catch (e) {
        console.log(e)
      } finally {
        this.btnLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.deliveryTimeManage {
  .tips {
    font-size: 13px;
    margin-top: 10px;
    color: #999;
  }
}
</style>
